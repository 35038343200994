<template>
  <el-container>
        <div class="menu-left" :style="'top: ' + stickyHeight">
          <div class="menu-nav" v-for="(item,index) in asideLists" :key="index">
            <div class="menu-title">{{item.title}}</div>
            <div class="menu-child" v-for="(items,index) in item.chind" :key="index" @click="clickMenu(items)"
                 :class="path === items.path ? 'active':''">
              <span class="icon iconfont" :class="items.icon"></span>
              <span>{{items.title}}</span>
            </div>
          </div>
        </div>
        <el-main class="user-main">
          <!-- <keep-alive> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
        </el-main>
    </el-container>
</template>

<script>
import Userinfo from "./UserInfo";
import { mapGetters, mapActions } from "vuex";


export default {
  name: "HiringPlatform1Index",
  data() {
    return {
      asideLists: [],
      path: "",
      service_status: 1,
    };
  },
  mounted() {
    this.path = this.$route.path;
    // 判断状态
    this.CountMenu();
  },
  computed: {
    ...mapGetters(["getUser"]),
    stickyHeight() {
      return this.$store.getters.getStickyHeight
    },
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      if (!val.id) {
        this.CountMenu();
      }
    },
    $route(to, from){
      if (to.path !== from.path) this.path = to.path
    }
  },
  methods: {
    ...mapActions(["setUser"]),
    CountMenu() {
      // 判断状态
      let Menu = Userinfo.UserMenu;
      if (this.$store.getters.getUser.serviceStatus == 0) {
        let arr = [];
        for (let x = 0; x < Menu.length; x++) {
          if (Menu[x].title != "我是服务商") {
            arr.push(Menu[x]);
          }
        }
        this.asideLists = arr;
      } else {
        this.asideLists = Menu;
      }
    },
    clickMenu(items) {
      // this.path = items.path;
      this.$router.push({ path: items.path });
    },
    // 获取个人信息
    GetUserInfo() {
      let url =
        "/app-api/hiring/user/get?id=" +
        this.$store.getters.getUser.id;
      this.$axios
        .get(url)
        .then((res) => {
          if (res.data.code === 0) {
            let user = JSON.stringify(res.data.data);
            localStorage.setItem("user", user);
            this.setUser(res.data.data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  margin-bottom: 20px;
  background-color: #fff;
  color: #333;
  display: flex;
  height: 60px;
  align-items: center;
  position: sticky;
  top: 88px;
  z-index: 66;

  .imgs {
    margin: auto 30px;
    width: 80px;
    height: 45px;
    background-color: #ccc;
    // margin-right: 30px;
  }
}

.el-aside {
  // background-color: #fff;
  color: #333;
  text-align: center;
  height: 341px;
  // height: 400px;
}

.user-main {
  color: #333;
  margin-left: 30px;
  padding: 0;
  //width: calc(1440px - 271px - 30px);
  flex: 1;
}
/deep/ .el-menu {
  border-right: none !important;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
  border-radius: 8px 8px 8px 8px;
}
.container {
  //position: sticky;
  // top: 168px;
  //top: 100px;
}

//
.menu-left {
  width: 271px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box;
  height: 100%;
  position: sticky;
  top: 108px;
  padding: 20px 0;
  .menu-title {
    font-weight: bold;
    color: #333333;
    font-size: 24px;
    line-height: 50px;
    padding: 0 30px;
  }
  .menu-nav {
    
    .menu-child {
      display: flex;
      align-items: center;
      height: 55px;
      cursor: pointer;
      padding: 0 30px;
      &.active {
        i {
          color: #3d8bff;
        }
        span {
          color: #3d8bff;
        }
        background: #f5f9ff;
      }
      
      span + span {
        margin-left: 5px;
      }
      i {
        margin: 0 17px;
      }
      &:hover {
        i {
          color: #3d8bff;
        }
        .svg-icon {
          fill: #3d8bff;
        }
        span {
          
          color: #3d8bff;
        }
        background: #f5f9ff;
      }
    }
  }
}
</style>
