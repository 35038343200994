export default {
    UserMenu: [
        {
            title: "个人中心",
            chind: [
                {
                    // icon: "el-icon-user",
                    icon: 'icon-self',
                    title: "个人信息",
                    path: "/UserInfo/UserHome",
                    index: "1",
                },
                {
                    // icon: "el-icon-bank-card",
                    icon: 'el-icon-setting',
                    title: "账号设置",
                    path: "/UserInfo/PersonInfo",
                    index: "9",
                }
            ],
        },
        {
            title: "我是雇主",
            chind: [
                {
                    // icon: "el-icon-tickets",
                    icon: 'icon-order',
                    title: "我的订单",
                    path: "/UserInfo/Order", //myOrder
                    index: "2",
                },
                {
                    // icon: "el-icon-document-add",
                    icon: 'icon-edit',
                    title: "发布需求",
                    path: "/UserInfo/Demand", //serveInfoIndent
                    index: "3",
                },
            ],
        },
        {
            title: "我是服务商",
            chind: [
                {
                    // icon: "el-icon-office-building",
                    icon: 'icon-provider',
                    title: "商家信息",
                    path: "/UserInfo/providerInfo",
                    index: "4",
                },
                {
                    // icon: "el-icon-user",
                    icon: 'icon-my',
                    title: "我的服务",
                    path: "/UserInfo/serveInfo",
                    index: "5",
                },
                {
                    // icon: "el-icon-tickets",
                    icon: 'icon-service',
                    title: "服务订单",
                    path: "/UserInfo/serveInfoOrder", //serveInfoIndent
                    index: "6",
                },
                {
                    // icon: "el-icon-star-off",
                    icon: 'icon-task',
                    title: "任务订单",
                    path: "/UserInfo/DemandCollection", //serveInfoIndent
                    index: "7",
                },
            ],
        },
        // {
        //     title: "我是需求方",
        //     chind: [
        //         {
        //             icon: "el-icon-s-custom",
        //             title: "需求方信息",
        //             path: "/UserInfo/demandInfo",
        //             index: "6",
        //         },
        //     ],
        // },
        {
            title: '特色业务',
            chind: [
                {
                    // icon: "el-icon-bank-card",
                    icon: 'icon-money',
                    title: "资金管理",
                    path: "/UserInfo/FundMent",
                    index: "8",
                },

            ]
        }
    ]
}